export class HttpConstants {

    static BASE_URL = 'http://node4.gabor.cloud:8082/drive-log-calc';
    static API_URL = '/api/';

    static REQUEST_URL = {
        GET_CHECK:      HttpConstants.BASE_URL + HttpConstants.API_URL + 'check',
        GET_VEHICLES:   HttpConstants.BASE_URL + HttpConstants.API_URL + 'vehicles',
        POST_VEHICLE:   HttpConstants.BASE_URL + HttpConstants.API_URL + 'savevehicle',
    };
}
