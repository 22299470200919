import { Component, OnInit } from '@angular/core';
import { VehicleService } from '../../services';
import { Vehicle } from '../../dto';

@Component({
    selector: 'vehicles',
    templateUrl: 'vehicles.component.html'
})
export class VehiclesComponent implements OnInit {

    private vehicles: Vehicle[];
    private displayEdit: boolean;
    private editingVehicle: Vehicle;

    constructor(private vehicleService: VehicleService) { }

    ngOnInit(): void {
        this.vehicleService.getVehicles().subscribe((result) => {
            this.vehicles = result.data;
        });
    }

    addVehicle() {
        this.editingVehicle = new Vehicle();
        this.displayEdit = true;
    }

    editVehicle(vehicle: Vehicle) {
        this.editingVehicle = Object.assign({}, vehicle);
        this.displayEdit = true;
    }

    saveEditingVehicle() {
        this.vehicleService.saveVehicle(this.editingVehicle).subscribe(
            () => {
                // TODO vyhodit
                this.vehicleService.getVehicles().subscribe((result) => {
                    this.vehicles = result.data;
                });
                this.editVehicle = null;
                this.displayEdit = false;
            }
        );
    }

    cancelEditing() {
        this.editVehicle = null;
        this.displayEdit = false;
    }

}
