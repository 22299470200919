import { NgModule, ModuleWithProviders } from '@angular/core';
import { VehicleService } from './vehicle.service';
import { AuthenticationService } from './authentication.service';

@NgModule({
})
export class ServicesModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ServicesModule,
            providers: [
                VehicleService,
                AuthenticationService
            ]
        };
    }
}
