import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { HttpConstants } from './http-constants';

/**
 * Service for work with vehicles
 */
@Injectable()
export class AuthenticationService {

    constructor(private http: HttpClient) { }

    login(userName: string, password: string): Observable<boolean> {
        const authData = window.btoa(userName + ':' + password);
        localStorage.setItem('currentUserAuth', authData);

        return Observable.create((observer) => {
            this.http.get(HttpConstants.REQUEST_URL.GET_CHECK).subscribe(() => {
                observer.next(true);
                observer.complete();
            },
            (error) => {
                console.log('ERROR ON LOG IN: ', error);
                observer.next(false);
                observer.complete();
            });
        });
    }

}
