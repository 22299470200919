import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable()
export class BasicAuthHttpInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        const userAuth = localStorage.getItem('currentUserAuth');
        if (userAuth) {
            console.log('Basic ' + userAuth);
            const request = req.clone({
                setHeaders: {
                    Authorization: 'Basic ' + userAuth
                }
            });
            return next.handle(request);
        }

        return next.handle(req);
    }

}
