export class Vehicle {
    id: number;
    name: string;
    spz: string;
    type: string;
    consumption: number;
    amortization: number;
    validFrom: string;
    validTo: string;
}
