import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Vehicle } from '../dto';
import { HttpConstants } from './http-constants';

/**
 * Service for work with vehicles
 */
@Injectable()
export class VehicleService {

    constructor(private http: HttpClient) { }

    getVehicles(): Observable<any> {
        return this.http.get<any>(HttpConstants.REQUEST_URL.GET_VEHICLES);
    }

    saveVehicle(vehicle: Vehicle): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post(HttpConstants.REQUEST_URL.POST_VEHICLE, JSON.stringify(vehicle), { headers });
    }

}
